import ReportErrorTemplate from '../views/report-error-modal.pug';
import PAF from 'shared/js/paf.js';

export default class ReportErrorModal {
  constructor({ error, apiCall }) {
    this.error = error;
    this.apiCall = apiCall;
  }

  mount(modalArea) {
    const error = this.error;
    const apiCall = this.apiCall;
    console.log('reportErrorModal: ', error);
    this.$modal_space = $(modalArea);
    const reportErrorTemplate = ReportErrorTemplate({
      error,
      api_call: apiCall,
    });

    this.$modal_space.append(reportErrorTemplate);
    this.$reportErrorModal = this.$modal_space.find('.reportErrorModal');
    this.$userComments = this.$reportErrorModal.find('.user_report');
    this.$dismissError = this.$reportErrorModal.find('.dismiss-error-button');
    this.$closeModal = this.$reportErrorModal.find('.close');
    this.$reloadPage = this.$reportErrorModal.find('.reload-page-button');

    this.$reportErrorModal.modal('show');

    this.reportId = null;
    const report = this.generateReport();
    report.user_comments = this.$userComments.val();
    // First we always report even if not submitted, noting the id.
    // Note we don't report on an error with this call to prevent an infinite
    // loop
    PAF.Query('log/submitErrorReport', report, true)
      .then((id) => {
        this.reportId = id;
      });
    this.$dismissError.click(() => {
      this.maybeSubmitReport(false);
      this.$reportErrorModal.modal('hide');
      this.$reportErrorModal.remove();
    });
    this.$closeModal.click(() => {
      this.$reportErrorModal.modal('hide');
      this.$reportErrorModal.remove();
    });
    this.$reloadPage.click(() => {
      this.maybeSubmitReport(true);
    });
  }

  maybeSubmitReport(reload) {
    const userComments = this.$userComments.val();
    if (userComments) {
      console.log('submitting report!');
      const report = this.generateReport();
      report.user_comments = userComments;
      report.id = this.reportId;
      PAF.Query('log/submitErrorReport', report, true)
        .then(() => {
          if (reload) {
            window.location.reload();
          }
        });
    } else if (reload) {
      window.location.reload();
    }
  }

  generateReport() {
    const report = {
      location: window.location.href,
      api_call: this.apiCall,
      message: this.error,
    };
    return report;
  }
}
